@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
}

:root {
  --mainColor: #0e6eb8;
  --mainColorLight: #2d7fbe;
  --secondaryColor: #0eb8b8;
  --textColor: white;
  --filterColor: #95c5ea;
  --backgroundColor: #c0d7e9;
  --button-width: 5rem;
  --button-height: 3rem;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
}

nav a {
  margin: 0 2rem;
  color: var(--textColor);
  text-decoration: none;
  font-size: 2rem;
}

nav a:hover {
  color: rgba(255, 255, 255, 0.645);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 3rem;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 3rem;
  }

  nav a {
    font-size: 2rem;
  }
}

.filter-form {
  background-color: var(--filterColor);
  padding: 0.5rem 0rem;
  width: 100%;
}

.list-view h1 {
  background-color: var(--filterColor);
  padding: 0.5rem 0rem;
  width: 100%;
  text-align: center;
}

.filter-form input {
  height: var(--button-height);
  width: 15rem;
  margin: 1rem;
  text-align: center;
}

.filter-apply-button {
  background-color: #26f48d;
  border-radius: 0.5rem;
  height: var(--button-height);
  width: var(--button-width);
  margin: 0.1rem;
}

.filter-discard-button {
  background-color: #f94747;
  border-radius: 0.5rem;
  padding: 0 1rem;
  height: var(--button-height);
  width: var(--button-width);
  margin: 0.1rem;
}

.container {
  text-align: center;
  background-color: var(--filterColor);
}

.production-list {
  background-color: var(--backgroundColor);
  padding: 2rem 0;
}

.production-list ul {
  display: grid;
  gap: 1.2rem;
  justify-content: center;
  list-style-type: none;
}

.production-list-item {
  width: 20rem;
}

.footer {
  background-color: var(--filterColor);
  width: 100%;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add any additional form styling here */
}

.login-box input {
  height: var(--button-height);
  width: 15rem;
  margin: 1rem;
}

.login-box div {
  text-align: center;
}

.login-box button {
  background-color: #f4ea26;
  border-radius: 0.5rem;
  height: var(--button-height);
  width: var(--button-width);
  margin: 0.1rem;
}

/* WORK IN PROGRESS BELOW */

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  padding: 2px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #41c796;
  color: white;
}

.back-button {
  background-color: #f2eda6;
  border-radius: 0.5rem;
  height: var(--button-height);
  width: var(--button-width);
  margin: 0.5rem;
}
